import axios from '../utils/axios_utils';

export const fetchUsers = ({ state, dispatch }) => {
    dispatch('requestUsers');
    axios.get(state.endpoint)
        .then(({ data }) => dispatch('receiveUsersSuccess', data))
        .catch((error) => {
            dispatch('receiveUsersError', error);
        });
};

export const fetchReservation = ({ commit }, reservationId) => {
    axios
        .get(`/v1/reservations/${reservationId }`)
        .then(({ data }) => {
            commit('REQUEST_RESERVATION_ITEM', data);
        })
        .catch((error) => console.error(error));
};

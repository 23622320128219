import { CurrencyDirective } from 'vue-currency-input'

const Reservation = {
    data() {
        return {
            currencyFormat: {
                currency: 'USD',
                locale: 'en'
            },
            reservation: {
                guests: [],
                documents: [],
                notes: [],
                memberships: [],
                financial: {
                    contract_number: null,
                    date: null,
                    percent: null,
                    sales_price: null,
                    closing_cost: null,
                    total: null,
                    dp: null,
                    balance: null,
                    down_payment: null,
                    type: '',
                    status: ''
                },
            },
        };
    },

    directives: {
        currency: CurrencyDirective
    }
};

export default Reservation;

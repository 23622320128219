import * as types from './mutation_types';

export default {
    [types.REQUEST_MEMBERSHIPS](state) {
        state.isLoading = true;
    },
    [types.UPDATE_ENDPOINT](state, endpoint) {
        state.endpoint = endpoint;
    },
    [types.RECEIVE_MEMBERSHIPS_SUCCESS](state, data) {
        // Do any needed data transformation to the received payload here
        state.items = data;
        state.isLoading = false;
    }
}

import * as types from './mutation_types';

export default {
    [types.REQUEST_USERS](state) {
        state.isLoading = true;
    },
    [types.REQUEST_RESERVATION_ITEM](state, payload) {
        state.reservation = payload;
    },
}

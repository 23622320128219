var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card mb-4" },
    [
      _c("div", { staticClass: "card-header py-3" }, [
        _c("h3", { staticClass: "mb-0 font-thin" }, [
          _c("i", { staticClass: "ni ni-calendar-grid-58 text-red mt-1" }),
          _vm._v(" " + _vm._s(_vm.isNew ? "New" : "Edit") + " Reservation"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body py-2" }, [
        _c("div", { staticClass: "nav-wrapper" }, [
          _c(
            "ul",
            {
              staticClass: "nav nav-pills-tab nav-fill flex-column flex-md-row",
              attrs: { id: "tabs-icons-text", role: "tablist" },
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              !_vm.showTabDocuments && !_vm.onlyNotes
                ? _c("li", { staticClass: "nav-custom pr-2" }, [_vm._m(2)])
                : _vm._e(),
              _vm._v(" "),
              _vm.visibleTab && !_vm.onlyNotes
                ? _c("li", { staticClass: "nav-custom pr-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link mb-sm-3 mb-md-0 uppercase px-4",
                        attrs: {
                          id: "tabs-icons-text-5-tab",
                          "data-toggle": "tab",
                          href: "#tabs-icons-text-5",
                          role: "tab",
                          "aria-controls": "tabs-icons-text-5",
                          "aria-selected": "false",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchMembershipItems()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fas fa-coins" }),
                        _vm._v(" Membership"),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "nav-custom" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link mb-sm-3 mb-md-0 uppercase px-4",
                    attrs: {
                      id: "tabs-icons-text-3-tab",
                      "data-toggle": "tab",
                      href: "#tabs-icons-text-3",
                      role: "tab",
                      "aria-controls": "tabs-icons-text-3",
                      "aria-selected": "false",
                    },
                    on: { click: _vm.reLoadNotes },
                  },
                  [
                    _c("i", { staticClass: "fas fa-file-medical-alt" }),
                    _vm._v(" Notes"),
                  ]
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card shadow-none" }, [
          _c("div", { staticClass: "card-body px-0" }, [
            _c(
              "div",
              { staticClass: "tab-content", attrs: { id: "myTabContent" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade show active",
                    attrs: {
                      id: "tabs-icons-text-1",
                      role: "tabpanel",
                      "aria-labelledby": "tabs-icons-text-1-tab",
                    },
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-9 pr-3" }, [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "requested_at" },
                                  },
                                  [_vm._v("Date Requested:")]
                                ),
                                _vm._v(" "),
                                _c("date-picker", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    lang: _vm.lang,
                                    format: "DD/MM/YYYY",
                                    width: "100%",
                                    "input-class": "form-control",
                                    "input-attr": {
                                      name: "requested_at",
                                      id: "requested_at",
                                    },
                                    placeholder: "dd/mm/yyyy",
                                    readonly: "readonly",
                                    disabled: _vm.onlyView,
                                    "value-type": _vm.valueType,
                                  },
                                  model: {
                                    value: _vm.reservation.requested_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.reservation,
                                        "requested_at",
                                        $$v
                                      )
                                    },
                                    expression: "reservation.requested_at",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("requested_at"),
                                        expression:
                                          "errors.has('requested_at')",
                                      },
                                    ],
                                    staticClass:
                                      "help text-xs font-normal text-danger",
                                  },
                                  [
                                    _vm._v(
                                      "The Date Requested field is required."
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "date_completed" },
                                  },
                                  [_vm._v("Date Completed")]
                                ),
                                _vm._v(" "),
                                _c("date-picker", {
                                  attrs: {
                                    lang: _vm.lang,
                                    format: "DD/MM/YYYY",
                                    width: "100%",
                                    "input-class": "form-control",
                                    "input-attr": {
                                      name: "date_completed",
                                      id: "date_completed",
                                    },
                                    placeholder: "dd/mm/yyyy",
                                    disabled: _vm.onlyView,
                                    "value-type": _vm.valueType,
                                  },
                                  model: {
                                    value: _vm.reservation.completed_at,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.reservation,
                                        "completed_at",
                                        $$v
                                      )
                                    },
                                    expression: "reservation.completed_at",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3 pt-5 mt--3" }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "checkin" },
                                  },
                                  [_vm._v("Check In:")]
                                ),
                                _vm._v(" "),
                                _c("date-picker", {
                                  attrs: {
                                    lang: _vm.lang,
                                    format: "DD/MM/YYYY",
                                    width: "100%",
                                    "input-class": "form-control",
                                    "input-attr": {
                                      name: "checkin",
                                      id: "checkin",
                                    },
                                    placeholder: "dd/mm/yyyy",
                                    "calendar-button-icon":
                                      "fas fa-calendar-alt",
                                    disabled: _vm.onlyView,
                                    formatter: _vm.momentFormat,
                                    "value-type": _vm.valueType,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.formatStringDate(
                                        $event,
                                        "checkin"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.reservation.checkin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reservation, "checkin", $$v)
                                    },
                                    expression: "reservation.checkin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "checkout" },
                                  },
                                  [_vm._v("Check Out:")]
                                ),
                                _vm._v(" "),
                                _c("date-picker", {
                                  attrs: {
                                    lang: _vm.lang,
                                    format: "DD/MM/YYYY",
                                    width: "100%",
                                    "input-class": "form-control",
                                    "input-attr": {
                                      name: "checkout",
                                      id: "checkout",
                                    },
                                    placeholder: "dd/mm/yyyy",
                                    disabled: _vm.onlyView,
                                    "value-type": _vm.valueType,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.formatStringDate(
                                        $event,
                                        "checkout"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.reservation.checkout,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.reservation, "checkout", $$v)
                                    },
                                    expression: "reservation.checkout",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "pres_date" },
                                  },
                                  [_vm._v("Pres Date:")]
                                ),
                                _vm._v(" "),
                                _c("date-picker", {
                                  attrs: {
                                    lang: _vm.lang,
                                    format: "DD/MM/YYYY",
                                    width: "100%",
                                    "input-class": "form-control",
                                    "input-attr": {
                                      name: "pres_date",
                                      id: "pres_date",
                                    },
                                    placeholder: "dd/mm/yyyy",
                                    disabled: _vm.onlyView,
                                    formatter: _vm.momentFormat,
                                    "value-type": _vm.valueType,
                                  },
                                  model: {
                                    value: _vm.reservation.pres_date,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.reservation,
                                        "pres_date",
                                        $$v
                                      )
                                    },
                                    expression: "reservation.pres_date",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "nights" },
                                },
                                [_vm._v("Nights:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.nights,
                                    expression: "reservation.nights",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "nights",
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: _vm.reservation.nights },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.reservation,
                                      "nights",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "additional" },
                                },
                                [_vm._v("Additional:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.reservation.additional,
                                    expression: "reservation.additional",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  disabled: _vm.loading || _vm.onlyView,
                                  id: "additional",
                                },
                                domProps: { value: _vm.reservation.additional },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.reservation,
                                      "additional",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "rf" },
                                },
                                [_vm._v("RF:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.reservation.rf,
                                    expression: "reservation.rf",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  id: "rf",
                                  disabled: _vm.loading || _vm.onlyView,
                                },
                                domProps: { value: _vm.reservation.rf },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.reservation,
                                      "rf",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "paid" },
                                },
                                [_vm._v("Paid:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.totalPaid,
                                    expression: "totalPaid",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "paid",
                                  disabled: true,
                                },
                                domProps: { value: _vm.totalPaid },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.totalPaid = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "paid_mode" },
                                },
                                [_vm._v("Paid Mode:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.paid_mode_id,
                                      expression: "reservation.paid_mode_id",
                                    },
                                  ],
                                  staticClass: "form-control form-select",
                                  attrs: {
                                    id: "paid_mode",
                                    disabled:
                                      _vm.loading ||
                                      !_vm.resource.ready ||
                                      _vm.onlyView,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.reservation,
                                        "paid_mode_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", {
                                    attrs: { value: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.resource.ready
                                          ? "« Choose »"
                                          : "...Loading..."
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.resource.paid_mode,
                                    function (mode) {
                                      return _c(
                                        "option",
                                        { domProps: { value: mode.id } },
                                        [_vm._v(_vm._s(mode.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "agency_id" },
                                },
                                [_vm._v("Agency:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.agency_id,
                                      expression: "reservation.agency_id",
                                    },
                                  ],
                                  staticClass: "form-control form-select",
                                  attrs: {
                                    id: "agency_id",
                                    disabled:
                                      _vm.loading ||
                                      !_vm.resource.ready ||
                                      _vm.onlyView,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.reservation,
                                        "agency_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", {
                                    attrs: { value: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.resource.ready
                                          ? "« Choose »"
                                          : "...Loading..."
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.resource.agencies,
                                    function (agency) {
                                      return _c(
                                        "option",
                                        { domProps: { value: agency.id } },
                                        [_vm._v(_vm._s(agency.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "hotel_id" },
                                },
                                [_vm._v("Hotel:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.hotel_id,
                                      expression: "reservation.hotel_id",
                                    },
                                  ],
                                  staticClass:
                                    "form-control form-select focus:border-blue-200",
                                  attrs: {
                                    name: "hotel_id",
                                    id: "hotel_id",
                                    disabled:
                                      !_vm.resource.ready || _vm.onlyView,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.reservation,
                                          "hotel_id",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      _vm.loadUnits,
                                    ],
                                  },
                                },
                                [
                                  _c("option", {
                                    attrs: { value: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.resource.ready
                                          ? "« Choose »"
                                          : "...Loading..."
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(_vm.resource.hotels, function (hotel) {
                                    return _c(
                                      "option",
                                      { domProps: { value: hotel.id } },
                                      [_vm._v(_vm._s(hotel.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3 pr-0" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "unit_id" },
                                },
                                [_vm._v("Unit:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.unit_id,
                                      expression: "reservation.unit_id",
                                    },
                                  ],
                                  staticClass: "form-control form-select",
                                  attrs: {
                                    name: "unit_id",
                                    id: "unit_id",
                                    disabled: _vm.loading || _vm.onlyView,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.reservation,
                                        "unit_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("« Choose »"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.units, function (item) {
                                    return _c(
                                      "option",
                                      { domProps: { value: item.id } },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-xs-6 col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "confirmation_number" },
                                },
                                [_vm._v("Confirmation Number:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.confirmation_number,
                                    expression:
                                      "reservation.confirmation_number",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "confirmation_number",
                                  disabled: _vm.onlyView,
                                },
                                domProps: {
                                  value: _vm.reservation.confirmation_number,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.reservation,
                                      "confirmation_number",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-md-8" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex content-center pr-0 shadow border px-2 py-3 rounded w-auto",
                              },
                              [
                                _c("div", { staticClass: "col-auto px-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm mb-0",
                                      attrs: { for: "flight_time" },
                                    },
                                    [_vm._v("Flight Time:")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.reservation.flight_time,
                                          expression: "reservation.flight_time",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "time",
                                        id: "flight_time",
                                        disabled: _vm.isNew || _vm.onlyView,
                                      },
                                      domProps: {
                                        value: _vm.reservation.flight_time,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.reservation,
                                            "flight_time",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-auto px-1" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm mb-0",
                                      attrs: { for: "flight_information" },
                                    },
                                    [_vm._v("Flight Information:")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "input-group" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.reservation.flight_information,
                                          expression:
                                            "reservation.flight_information",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "flight_information",
                                        disabled: _vm.isNew || _vm.onlyView,
                                      },
                                      domProps: {
                                        value:
                                          _vm.reservation.flight_information,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.reservation,
                                            "flight_information",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-auto px-1" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn mt-4 bg-gray-300 text-blue-600 border border-gray-700 hover:shadow-none",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": "#email-transportation1",
                                        disabled: !this.reservation.flight_time,
                                      },
                                      on: { click: _vm.fillFlightInfo },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-envelope",
                                      }),
                                      _vm._v(" Preview Email "),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6 col-md-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "certificate_number" },
                                },
                                [_vm._v("Certificate Number:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.certificate_number,
                                    expression:
                                      "reservation.certificate_number",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "certificate_number",
                                  disabled: _vm.onlyView,
                                },
                                domProps: {
                                  value: _vm.reservation.certificate_number,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.reservation,
                                      "certificate_number",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-md-3 border-left border-gray-200 pl-3",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("date-picker", {
                                attrs: {
                                  lang: _vm.lang,
                                  format: "DD/MM/YYYY",
                                  width: "100%",
                                  "input-class": "form-control",
                                  "input-attr": { id: "cxl", name: "cxl" },
                                  placeholder: "dd/mm/yyyy",
                                  readonly: "readonly",
                                  disabled: _vm.onlyView,
                                },
                                model: {
                                  value: _vm.reservation.cxl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.reservation, "cxl", $$v)
                                  },
                                  expression: "reservation.cxl",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between items-center",
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "cxl_reason" },
                                  },
                                  [_vm._v("Cancelation Reason:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "px-2 py-1 bg-blue-600 rounded-sm mb-1 text-sm font-semibold text-white hover:bg-blue-700",
                                    attrs: { disabled: !_vm.reservation.cxl },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$modal.show("mdl-cxl-reason")
                                      },
                                    },
                                  },
                                  [_vm._v(" Add + ")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.cxl_reason,
                                    expression: "reservation.cxl_reason",
                                  },
                                ],
                                staticClass: "form-control form-select",
                                attrs: {
                                  id: "cxl_reason",
                                  disabled: !_vm.reservation.cxl,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.reservation,
                                      "cxl_reason",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("« Choose »"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.resource.cancelation_reasons,
                                  function (reason) {
                                    return _c(
                                      "option",
                                      {
                                        key: reason.id,
                                        domProps: { value: reason.value },
                                      },
                                      [_vm._v(_vm._s(reason.value))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mt-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "custom-control custom-radio mb-3",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.flag_color,
                                      expression: "reservation.flag_color",
                                    },
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    id: "6ab45a32ae59a",
                                    type: "radio",
                                    value: "red",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.reservation.flag_color,
                                      "red"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation,
                                        "flag_color",
                                        "red"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(4),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "custom-control custom-radio mb-3",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.flag_color,
                                      expression: "reservation.flag_color",
                                    },
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    id: "6ab45a32ae59c",
                                    type: "radio",
                                    value: "yellow",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.reservation.flag_color,
                                      "yellow"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation,
                                        "flag_color",
                                        "yellow"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(5),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "custom-control custom-radio mb-3",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.flag_color,
                                      expression: "reservation.flag_color",
                                    },
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    id: "6ab45a32ae59e",
                                    type: "radio",
                                    value: "green",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.reservation.flag_color,
                                      "green"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.reservation,
                                        "flag_color",
                                        "green"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(6),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group mt-4" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center space-x-3 my-2 mb-3 ",
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "text-sm font-semibold",
                                      attrs: { for: "flag_arrival" },
                                    },
                                    [_vm._v("Pre-Arrival:")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-outline-danger",
                                      on: {
                                        click: function ($event) {
                                          _vm.reservation.flag_arrival = null
                                        },
                                      },
                                    },
                                    [_vm._v("Clear Selection")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.valuesFlagArrival,
                                function (preArrival) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-radio mb-3",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.reservation.flag_arrival,
                                            expression:
                                              "reservation.flag_arrival",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          id: preArrival.value + "pre_Arrival_",
                                          type: "radio",
                                        },
                                        domProps: {
                                          value: preArrival.value,
                                          checked: _vm._q(
                                            _vm.reservation.flag_arrival,
                                            preArrival.value
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.reservation,
                                              "flag_arrival",
                                              preArrival.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "custom-control-label flex space-x-3 items-center",
                                          attrs: {
                                            for:
                                              preArrival.value + "pre_Arrival_",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-plane-arrival fa-2x",
                                            class: [
                                              preArrival.value == "red"
                                                ? "text-red-600"
                                                : "",
                                              preArrival.value == "orange"
                                                ? "text-yellow-500"
                                                : "",
                                              preArrival.value == "green"
                                                ? "text-green-500"
                                                : "",
                                              preArrival.value == "blue"
                                                ? "text-blue-500"
                                                : "",
                                            ],
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(preArrival.text)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    attrs: {
                      id: "tabs-icons-text-2",
                      role: "tabpanel",
                      "aria-labelledby": "tabs-icons-text-2-tab",
                    },
                  },
                  [
                    _vm._l(_vm.reservation.guests, function (guest, index) {
                      return _c(
                        "div",
                        {
                          staticClass:
                            "form-row border-b border-blue-400 border-dotted mb-2",
                        },
                        [
                          _c("div", { staticClass: "col-md-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", {
                                staticClass: "text-sm",
                                attrs: { for: "name_" + index },
                                domProps: {
                                  innerHTML: _vm._s(
                                    guest.is_principal === true
                                      ? "Name (Principal):"
                                      : "Name (Spouse):"
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.name,
                                    expression: "guest.name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "name_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(guest, "name", $event.target.value)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "age_" + index },
                                },
                                [_vm._v("Age:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.age,
                                    expression: "guest.age",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "age_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.age },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(guest, "age", $event.target.value)
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-2" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "ethnic_group_" + index },
                                },
                                [_vm._v("Ethnic Group:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: guest.ethnic_group,
                                      expression: "guest.ethnic_group",
                                    },
                                  ],
                                  staticClass: "form-control form-select",
                                  attrs: {
                                    id: "ethnic_group_" + index,
                                    disabled: _vm.onlyView,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        guest,
                                        "ethnic_group",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v("« Choose »"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "NORTH AMERICAN CAUCASIAN",
                                      },
                                    },
                                    [_vm._v("NORTH AMERICAN CAUCASIAN")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "AFRO-AMERICAN" } },
                                    [_vm._v("AFRO-AMERICAN")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "LATINOS" } },
                                    [_vm._v("LATINOS")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "ASIAN (CHINESE,JAPANESE,THAI)",
                                      },
                                    },
                                    [_vm._v("ASIAN (CHINESE,JAPANESE,THAI)")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "EASTERN EUROPE" } },
                                    [_vm._v("EASTERN EUROPE")]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "HINDUS" } }, [
                                    _vm._v("HINDUS"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "FRENCH CANADIANS" } },
                                    [_vm._v("FRENCH CANADIANS")]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "MIXED" } }, [
                                    _vm._v("MIXED"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "OTHER" } }, [
                                    _vm._v("OTHER"),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "phone_" + index },
                                },
                                [_vm._v("Phone:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.phone,
                                    expression: "guest.phone",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "phone_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.phone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      guest,
                                      "phone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "alternative_phone_" + index },
                                },
                                [_vm._v("Alternative Phone:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.alternative_phone,
                                    expression: "guest.alternative_phone",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "alternative_phone_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.alternative_phone },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      guest,
                                      "alternative_phone",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "email_" + index },
                                },
                                [_vm._v("Email:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.email,
                                    expression: "guest.email",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "email_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      guest,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-sm",
                                  attrs: { for: "alternative_email_" + index },
                                },
                                [_vm._v("Alternative Email:")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: guest.alternative_email,
                                    expression: "guest.alternative_email",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "alternative_email_" + index,
                                  disabled: _vm.onlyView,
                                },
                                domProps: { value: guest.alternative_email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      guest,
                                      "alternative_email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "text-sm",
                                attrs: { for: "adults" },
                              },
                              [_vm._v("Adults:")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reservation.adults,
                                  expression: "reservation.adults",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "adults",
                                name: "adults",
                                disabled: _vm.onlyView,
                              },
                              domProps: { value: _vm.reservation.adults },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.reservation,
                                    "adults",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("adults"),
                                    expression: "errors.has('adults')",
                                  },
                                ],
                                staticClass:
                                  "help text-xs font-normal text-danger",
                              },
                              [
                                _vm._v(
                                  "\n                                        The Date Requested field is required.\n                                    "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-2" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "text-sm",
                                attrs: { for: "childs" },
                              },
                              [_vm._v("Children:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reservation.children,
                                    expression: "reservation.children",
                                  },
                                ],
                                staticClass: "form-control form-select",
                                attrs: { id: "childs", disabled: _vm.onlyView },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.reservation,
                                      "children",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v("« Choose »"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.childs, function (children) {
                                  return _c(
                                    "option",
                                    {
                                      attrs: { id: children },
                                      domProps: { value: ++children },
                                    },
                                    [_vm._v(_vm._s(children))]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.inputChildAges, function (age, index) {
                          return _c(
                            "div",
                            {
                              key: "age-child-" + index,
                              staticClass: "flex col-md-2 pl-2 mr-0",
                            },
                            [
                              _c("div", { staticClass: "form-group w-full" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "age_child" },
                                  },
                                  [_vm._v("Child Age :")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.reservation.kid_age[index],
                                      expression: "reservation.kid_age[index]",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: { required: true },
                                      expression: "{required :true}",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    min: "1",
                                    max: "17",
                                    id: "age_child",
                                    name: "age-child-" + index,
                                  },
                                  domProps: {
                                    value: _vm.reservation.kid_age[index],
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.reservation.kid_age,
                                        index,
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has(
                                          "age-child-" + index
                                        ),
                                        expression:
                                          "errors.has(`age-child-${index}`)",
                                      },
                                    ],
                                    staticClass:
                                      "help text-xs font-normal text-danger",
                                  },
                                  [
                                    _vm._v(
                                      "The Child Age " +
                                        _vm._s(age) +
                                        " field is required."
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "text-sm",
                              attrs: { for: "country" },
                            },
                            [_vm._v("Country:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reservation.country_id,
                                  expression: "reservation.country_id",
                                },
                              ],
                              staticClass: "form-control form-select",
                              attrs: { id: "country", disabled: _vm.onlyView },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.reservation,
                                      "country_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  _vm.loadStates,
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("« Choose »"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.resource.countries,
                                function (country) {
                                  return _c(
                                    "option",
                                    {
                                      attrs: { id: country.id },
                                      domProps: { value: country.id },
                                    },
                                    [_vm._v(_vm._s(country.name))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "text-sm", attrs: { for: "state" } },
                            [_vm._v("State:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reservation.state_id,
                                  expression: "reservation.state_id",
                                },
                              ],
                              staticClass: "form-control form-select",
                              attrs: {
                                id: "state",
                                disabled: _vm.disabledStates || _vm.onlyView,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.reservation,
                                    "state_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("« Choose »"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.resource.states, function (item) {
                                return _c(
                                  "option",
                                  {
                                    staticClass: "uppercase",
                                    domProps: { value: item.id },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-2 pt-5 mt--3" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { staticClass: "text-sm", attrs: { for: "cohab" } },
                            [_vm._v("Cohab:")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.reservation.cohab,
                                expression: "reservation.cohab",
                              },
                            ],
                            staticClass: "form-checkbox",
                            attrs: {
                              type: "checkbox",
                              id: "cohab",
                              disabled: _vm.onlyView,
                            },
                            domProps: {
                              checked: Array.isArray(_vm.reservation.cohab)
                                ? _vm._i(_vm.reservation.cohab, null) > -1
                                : _vm.reservation.cohab,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.reservation.cohab,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.reservation,
                                        "cohab",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.reservation,
                                        "cohab",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.reservation, "cohab", $$c)
                                }
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    attrs: {
                      id: "tabs-icons-text-3",
                      role: "tabpanel",
                      "aria-labelledby": "tabs-icons-text-3-tab",
                    },
                  },
                  [
                    _vm.isOnlyNotes
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-6 col-md-10 pr-0" },
                            [
                              _c("div", { staticClass: "form-group mb-0" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "text-sm",
                                    attrs: { for: "notes" },
                                  },
                                  [_vm._v("Notes:")]
                                ),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.note.description,
                                      expression: "note.description",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    rows: "2",
                                    name: "notes",
                                    id: "notes",
                                  },
                                  domProps: { value: _vm.note.description },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.note,
                                        "description",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                !_vm.isNew
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm hover:text-white border-green-400 hover:bg-green-500 hover:border-green-600 mt-2",
                                        attrs: { disabled: !_vm.isOnlyNotes },
                                        on: { click: _vm.saveNote },
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-save" }),
                                        _vm._v(
                                          " Save Note\n                                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isNew
                      ? _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("div", { staticClass: "w-full" }, [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("div", { staticClass: "table-responsive" }, [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "table align-items-center table-flush table-fixed",
                                  },
                                  [
                                    _c(
                                      "thead",
                                      { staticClass: "thead-light" },
                                      [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "uppercase w-1/12 px-2",
                                              attrs: { scope: "col" },
                                            },
                                            [_vm._v("#")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "uppercase w-8/12 px-2",
                                              attrs: { scope: "col" },
                                            },
                                            [_vm._v("Description")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass:
                                                "uppercase w-3/12 px-2",
                                              attrs: { scope: "col" },
                                            },
                                            [_vm._v("Created At")]
                                          ),
                                          _vm._v(" "),
                                          _vm.onlyNotes
                                            ? _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "uppercase w-3/12 px-2",
                                                  attrs: { scope: "col" },
                                                },
                                                [_vm._v("Created By")]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      { staticClass: "list" },
                                      _vm._l(
                                        _vm.reservation.notes,
                                        function (note, index) {
                                          return _c("tr", { key: index }, [
                                            _c(
                                              "td",
                                              { staticClass: "px-2 text-left" },
                                              [_vm._v(_vm._s(index + 1))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass: "px-2",
                                                staticStyle: {
                                                  "white-space":
                                                    "normal !important",
                                                },
                                              },
                                              [
                                                _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      note.description
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: note.hasItems,
                                                        expression:
                                                          "note.hasItems",
                                                      },
                                                    ],
                                                    staticClass: "pl-4",
                                                  },
                                                  _vm._l(
                                                    note.items,
                                                    function (el, key) {
                                                      return _c("span", {
                                                        key: key,
                                                        staticClass:
                                                          "block text-green-600 font-sans",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            el.title
                                                          ),
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", { staticClass: "px-2" }, [
                                              _vm._v(_vm._s(note.createdAt)),
                                            ]),
                                            _vm._v(" "),
                                            _vm.onlyNotes
                                              ? _c(
                                                  "td",
                                                  { staticClass: "px-2" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(note.createdBy)
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                !_vm.showTabDocuments
                  ? _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        attrs: {
                          id: "tabs-icons-text-4",
                          role: "tabpanel",
                          "aria-labelledby": "tabs-icons-text-4-tab",
                        },
                      },
                      [
                        !_vm.onlyView
                          ? _c(
                              "div",
                              { staticClass: "form-row align-items-center" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-auto" },
                                  [
                                    _c("input-file", {
                                      ref: "file-input",
                                      attrs: {
                                        name: "documents",
                                        accept: "application/pdf",
                                      },
                                      on: { change: _vm.uploadFieldChange },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-light rounded-none w-full block mb-2",
                                        attrs: { type: "button" },
                                        on: { click: _vm.upload },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas",
                                          class: [
                                            _vm.loading
                                              ? "fa-spinner fa-spin"
                                              : "fa-upload",
                                          ],
                                        }),
                                        _vm._v(" Upload"),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("div", { staticClass: "w-full" }, [
                              _vm._m(8),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "table-responsive" },
                                [
                                  _vm.loading
                                    ? _c("base-loader", {
                                        staticClass: "table-loader",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table align-items-center table-flush",
                                    },
                                    [
                                      _vm._m(9),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        { staticClass: "list" },
                                        _vm._l(
                                          _vm.reservation.documents,
                                          function (document) {
                                            return _c("tr", [
                                              _c("td", [
                                                _vm._v(_vm._s(document.id)),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(_vm._s(document.name)),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-outline-info text-blue-400 border-0",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.preview(
                                                          document
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fas fa-eye",
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-outline-danger border-0",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeDocument(
                                                          document
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-trash-alt",
                                                    }),
                                                  ]
                                                ),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    attrs: {
                      id: "tabs-icons-text-5",
                      role: "tabpanel",
                      "aria-labelledby": "tabs-icons-text-5-tab",
                    },
                  },
                  [_c("Memberships")],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "my-3" }),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn border-gray-600 hover:bg-gray-600 hover:text-white py-1",
                attrs: { type: "button" },
                on: { click: _vm.goBackWindows },
              },
              [
                _c("i", { staticClass: "fas fa-chevron-left" }),
                _vm._v(" Back\n                "),
              ]
            ),
            _vm._v(" "),
            _vm.isNew
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white py-1",
                    attrs: { type: "submit" },
                    on: { click: _vm.saveReservation },
                  },
                  [
                    _c("i", {
                      staticClass: "fas",
                      class: [_vm.loading ? "fa-spinner fa-spin" : "fa-save"],
                    }),
                    _vm._v(" Save\n                "),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isNew
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white py-1",
                    attrs: { type: "submit", disabled: !_vm.isOnlyNotes },
                    on: { click: _vm.updateReservation },
                  },
                  [
                    _c("i", {
                      staticClass: "fas",
                      class: [_vm.loading ? "fa-spinner fa-spin" : "fa-edit"],
                    }),
                    _vm._v(" Update\n                "),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "email-transportation",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "exampleModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(10),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body pb-0" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flight_info.description,
                        expression: "flight_info.description",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "description", rows: "10", disabled: true },
                    domProps: { value: _vm.flight_info.description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.flight_info,
                          "description",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer py-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v("Close")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.sendEmailTransportation },
                    },
                    [_vm._v("Send email")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("modal", { attrs: { name: "mdl-cxl-reason", height: "260px" } }, [
        _c(
          "div",
          {
            staticClass:
              "flex items-end justify-center min-h-screen text-center sm:block sm:p-0",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "relative block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "hidden sm:block absolute top-0 right-0 pt-4 pr-4",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.$modal.hide("mdl-cxl-reason")
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "h-6 w-6",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              "aria-hidden": "true",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                "stroke-width": "2",
                                d: "M6 18L18 6M6 6l12 12",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "sm:flex sm:items-start" }, [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3 w-full text-left sm:mt-0 sm:text-left",
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-lg leading-6 font-medium text-gray-900",
                          attrs: { id: "modal-title" },
                        },
                        [_vm._v("New Reason")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cancelReason,
                              expression: "cancelReason",
                            },
                          ],
                          staticClass:
                            "form-input block w-full sm:text-sm sm:leading-5",
                          attrs: { type: "text", placeholder: "Reason" },
                          domProps: { value: _vm.cancelReason },
                          on: {
                            change: function ($event) {
                              _vm.cancelReason =
                                $event.target.value.toUpperCase()
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.cancelReason = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.isTakenReason
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-red-500 block w-full text-sm font-semibold",
                              },
                              [_vm._v("The value has already been taken")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt-5 sm:mt-4 sm:flex justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm",
                      attrs: { type: "button" },
                      on: { click: _vm.saveNewCancelationReason },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-custom pr-2 flex-none" }, [
      _c(
        "a",
        {
          staticClass: "nav-link mb-sm-3 mb-md-0 uppercase px-4 active",
          attrs: {
            id: "tabs-icons-text-1-tab",
            "data-toggle": "tab",
            href: "#tabs-icons-text-1",
            role: "tab",
            "aria-controls": "tabs-icons-text-1",
            "aria-selected": "true",
          },
        },
        [
          _c("i", { staticClass: "fas fa-book text-green-800 mr-2" }),
          _vm._v(" Reservation"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-custom pr-2" }, [
      _c(
        "a",
        {
          staticClass: "nav-link mb-sm-3 mb-md-0 uppercase px-4",
          attrs: {
            id: "tabs-icons-text-2-tab",
            "data-toggle": "tab",
            href: "#tabs-icons-text-2",
            role: "tab",
            "aria-controls": "tabs-icons-text-2",
            "aria-selected": "false",
          },
        },
        [
          _c("i", { staticClass: "fas fa-users-cog" }),
          _vm._v(" Guest Information"),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link mb-sm-3 mb-md-0 uppercase px-4",
        attrs: {
          id: "tabs-icons-text-4-tab",
          "data-toggle": "tab",
          href: "#tabs-icons-text-4",
          role: "tab",
          "aria-controls": "tabs-icons-text-4",
          "aria-selected": "false",
        },
      },
      [_c("i", { staticClass: "fas fa-file-archive" }), _vm._v(" Documents")]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "text-sm", attrs: { for: "cxl" } }, [
      _vm._v("Cxl: "),
      _c("span", { staticClass: "italic text-sm text-gray-500" }, [
        _vm._v("(Cancelation)"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "custom-control-label", attrs: { for: "6ab45a32ae59a" } },
      [_c("i", { staticClass: "fas fa-flag fa-2x text-red-500" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "custom-control-label", attrs: { for: "6ab45a32ae59c" } },
      [_c("i", { staticClass: "fas fa-flag fa-2x text-yellow-500" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "custom-control-label", attrs: { for: "6ab45a32ae59e" } },
      [_c("i", { staticClass: "fas fa-flag fa-2x text-green-500" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0 py-1" }, [
      _c("div", { staticClass: "row" }, [
        _c("h3", { staticClass: "mb-0 font-thin" }, [
          _c("i", { staticClass: "fa fa-file text-green-600" }),
          _vm._v(" Notes"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0 py-0" }, [
      _c("div", { staticClass: "row py-2" }, [
        _c("h3", { staticClass: "mb-0 font-thin text-gray-600" }, [
          _c("i", { staticClass: "far fa-file-pdf text-green-600" }),
          _vm._v(" Documents"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "uppercase", attrs: { scope: "col" } }, [
          _vm._v("#"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "uppercase", attrs: { scope: "col" } }, [
          _vm._v("Name"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "uppercase", attrs: { scope: "col" } }, [
          _vm._v("Actions"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-header border-bottom border-gray-200" },
      [
        _c(
          "h5",
          { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
          [
            _vm._v(
              "\n                        Flight Information\n                    "
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
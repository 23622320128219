<script>
    import ReservationComponent from "../components/ReservationComponent";
    export default {
        components : { ReservationComponent },
        props: {
            reservation : {
                type : Object,
                required : true
            }
        }
    }
</script>
<template>
    <reservation-component
        :is-new="false"
        :item="reservation"
        :only-view="true"
    />
</template>

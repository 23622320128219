<script>
    import ReservationComponent from "../components/ReservationComponent";

    export default {
        components: {
            ReservationComponent
        },

        data() {
            return {
                form: {
                    requested_at: null,
                    completed_at: null,
                    checkin: null,
                    checkout: null,
                    pres_date: null,
                    nights: null,
                    additional: null,
                    rf: null,
                    paid: null,
                    paid_mode_id: '',
                    agency_id: '',
                    hotel_id: '',
                    unit_id: '',
                    confirmation_number: null,
                    certificate_number: null,
                    flight_time: null,
                    flight_information: null,
                    cxl: null,
                    cxl_reason: null,
                    guests: [{
                        name: null,
                        age: null,
                        ethnic_group: '',
                        is_principal: true,
                        sex: 'male'
                    },{
                        name: null,
                        age: null,
                        ethnic_group: '',
                        is_principal: false,
                        sex: 'female'
                    }],
                    adults: 2,
                    children: null,
                    country_id: '',
                    state_id: '',
                    cohab: false,
                    kid_age : [],
                    notes : [],
                    financial: {
                        contract_number: null,
                        date: null,
                        sales_price: null,
                        closing_cost: null,
                        total: null,
                        dp: null,
                        balance: null,
                        down_payment: null,
                        percent: null,
                        status: '',
                    },
                }
            }
        }
    }
</script>
<template>
    <div class="new-reservation">
        <ReservationComponent :item="form" />
    </div>
</template>

const apiToken = {
  init() {
    const tokenEl = Laravel.apiToken;

    if (tokenEl !== null) {
      this.api_Token = 'Bearer ' + tokenEl;
    } else {
      this.api_Token = null;
    }
  },

  get token() {
    return this.api_Token;
  },

  get headerKey() {
    return 'Authorization';
  },

  get headers() {
    if (this.api_Token !== null) {
      return {
        [this.headerKey]: this.token,
      };
    }
    return {};
  },
};

apiToken.init();

export default apiToken;

import _ from 'lodash'
import axios from 'axios'
import Vue from 'vue'

const Bus = new Vue();

export default {
    methods: {

        /**
         * Creates a debounced function that delays invoking a callback.
         */
        debouncer: _.debounce(callback => callback(), 500),

        http() {
            const instance = axios.create({
                baseURL: '/api'
            });

            instance.interceptors.response.use(
                response => response,
                error => {
                    switch (error.response.status) {
                        case 500:
                            Bus.$emit('httpError', error.response.data.message);
                            break;

                        //case 401:
                        //    window.location.reload();
                        //    break;

                        case 419:
                            window.location.reload();
                            break;
                    }
                    return Promise.reject(error);
                }
            );
            return instance;
        }
    }
}

require('./bootstrap');

import Vue from 'vue';

require('./plugins');
require('./components/base');

import VeeValidate from 'vee-validate';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';

import Base from './base'
import store from './store'

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

//Vue.component('view-reservation', require('./screens/ViewReservation.vue').default);
//Vue.component('new-reservation', require('./screens/NewReservation.vue').default);
//Vue.component('edit-reservation', require('./screens/EditReservation.vue').default);
//Vue.component('rates', require('./screens/Rates.vue').default);

Vue.mixin(Base);

Vue.use(VeeValidate);
Vue.use(Loading);
Vue.use(VueToast);

new Vue({
    el: '#app',
    store
});

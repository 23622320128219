import axios from 'axios';
import csrf from './csrf';
import apiToken from './api_token';
import suppressAjaxErrorsDuringNavigation from './suppress_ajax_errors_during_navigation'

const instance = axios.create({
    baseURL: '/api'
});

instance.defaults.headers.common[csrf.headerKey] = csrf.token;
instance.defaults.headers.common[apiToken.headerKey] = apiToken.token;

let isUserNavigating = false;
window.addEventListener('beforeunload', () => {
    isUserNavigating = true;
});

// Ignore AJAX errors caused by requests
// being cancelled due to browser navigation
const { gon } = window;
const featureFlagEnabled = gon && gon.features && gon.features.suppressAjaxNavigationErrors;
instance.interceptors.response.use(
    response => response,
    err => suppressAjaxErrorsDuringNavigation(err, isUserNavigating, featureFlagEnabled),
);


export default instance;

import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import * as actions from './actions';
import * as getters from './getters';
import state from "./state";
import mutations from './mutations';
import memberships from './modules/memberships';
import reservations from './modules/reservations';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

console.log(debug, 'strict mode')

export default new Vuex.Store({
    strict: debug,
    plugins: debug ? [createLogger()] : [],
    actions,
    state,
    getters,
    mutations,
    modules: {
        memberships,
        reservations
    },
});

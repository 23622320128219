import moment from 'moment';
import 'moment-timezone'

const formatSpecification = [
    'MM/DD/YYYY hh:mm',
    'YYYY-MM-DD hh:mm:ss',
    'MMDDYYYY',
    'YYYY-MM-DD',
    'LL',
]

const Customs = {
    data() {
        return {
            lang: {
                days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                months: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                pickers: [
                    'next 7 days',
                    'next 30 days',
                    'previous 7 days',
                    'previous 30 days',
                ],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range',
                },
            },
        };
    },

    methods: {
        momentInstance(value) {
            moment.tz.setDefault('America/Mexico_City')
            return moment(value, formatSpecification).tz('America/Mexico_City');
        },
        formatDate(value, format = 'MM/DD/YYYY') {
            return this.momentInstance(value).format(format)
        },
        //Add Days
        addDays(value, days = 15, format='MM/DD/YYYY') {
            return this.momentInstance(value).add(days, 'days').format(format)
        },
        customFormatter(date, formatString = 'YYYY-MM-DD hh:mm:ss') {
            return date === null ? null : this.momentInstance(date).format(formatString);
        }
    },

    filters: {
        toCurrency : function(value) {
            if (typeof value !== "number") {
                return value;
            }
            return '$' + parseFloat(value).toFixed(2);
        }
    }
};

export default Customs;

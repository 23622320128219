var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex w-full items-center justify-center" }, [
    _c(
      "label",
      {
        staticClass:
          "w-48 flex flex-col items-center px-1 py-4 bg-white text-blue-600 hover:text-blue-300 rounded-lg shadow-lg tracking-wide uppercase border-2 border-dotted cursor-pointer mb-1",
      },
      [
        _c(
          "svg",
          {
            staticClass: "w-8 h-8",
            attrs: {
              fill: "currentColor",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 20 20",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "mt-2 text-xs leading-normal" }, [
          _vm._v("Select a file"),
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "inputFile",
          staticClass: "hidden",
          attrs: {
            type: "file",
            name: _vm.name,
            multiple: _vm.multiple,
            accept: _vm.accept,
          },
          on: { change: _vm.handleChange },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<script>
    import Reservation from "../mixins/reservation";
    import Customs from "../mixins/customs";
    import DatePicker from 'vue2-datepicker'
    import { CurrencyInput } from 'vue-currency-input'
    import { mapActions } from 'vuex';
    const cancellationReasons = [
        'CLIENT REQUEST',
        'CHANGE OF DATE',
        'CORPORATIVO REQUEST',
        'COVID-19',
        'CS REPORT',
        'HEALTH ISSUES',
        'MAYAN PARADISE REQUEST',
        'MEXICAN TRAVEL REQUEST',
        'NO SHOW',
        'DEATH',
        'DISPUTED',
        'NOT QUALIFY',
        'RESGO REQUEST',
        'RESGO REQUEST/ RESORT FEE DISPUTED',
        'T/C VIOLATION',
        'UNRESPONSIVE',
    ]

    export default {
        mixins: [Reservation, Customs],

        props: {
            item: {
                type : Object,
                required : true
            },
            isNew: {
                type: Boolean,
                default: true,
            },
            onlyView: {
                type: Boolean,
                required: false,
                default: false
            },
            onlyNotes: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        components: {
            DatePicker,
            CurrencyInput
        },

        async mounted() {
            await this.loadReservationResources();
            if(this.isNew === false) {
                console.log("%c Edit Reservation v2", "color: #fff; background-color: #3490dc; padding: 5px 10px; border-radius: 5px; font-size: 1.2em;");
                await this.requestReservation(this.item);
                const reservationForm = JSON.parse(JSON.stringify(this.reservation));
                this.reservation = await this.parseDateFormate(reservationForm);
                this.visibleTab = true;
                this.prepareGuestInfo();
            }
        },

        data() {
            return {
                valueType: {
                    value2date: (value) => {
                        return this.formatDate(value, 'LL')
                    },
                    date2value: (date) => {
                        return this.formatDate(date, 'LL')
                    }
                },
                valuesFlagArrival: [
                    { value: 'blue', text: 'New Lead' },
                    { value: 'orange', text: 'Evaluating Proposal' },
                    { value: 'green', text: 'Sale Made' },
                    { value: 'red', text: 'Deal Lost' }
                ],
                momentFormat: {
                    parse: (value) => {

                        console.log(value, 'momentFormat')

                        return value ? this.customFormatter(value, 'YYYY-MM-DD') : null
                    },
                },
                loading: false,
                onUpdate: false,
                isNewRecord: false,
                disabledStates: true,
                visibleTab: false,
                cancelReason: '',
                isTakenReason: false,
                resource: {
                    ready: false,
                    hotels: [],
                    countries: [],
                    agencies: [],
                    states: [],
                    paid_mode: [],
                    cancelation_reasons: []
                },
                note: {
                    description : null
                },
                units: [],
                reservation : this.item,
                children: 0,
                childs : Array.from(Array(3).keys()),
                inputChildAges : [],
                attachments: [],
                data: new FormData(),
                documents : [],
                flight_info : {
                    description : null,
                    reservation_id : this.item.id
                }
            }
        },
        methods: {
            ...mapActions({
                requestReservation: 'reservations/receiveReservation',
                fetchMembershipItems: 'memberships/fetchMemberships',
            }),

            getNumberOfNights(start, end) {
                // Convert both dates to milliseconds
                let startDate = new Date(start).getTime();
                let endDate = new Date(end).getTime();

                // Calculate the number of milliseconds in one day
                const oneDay = 1000 * 60 * 60 * 24;

                // Calculate the difference in milliseconds
                let differenceMs = Math.abs(startDate - endDate);

                // Calculate the number of nights by dividing the difference by the number of milliseconds in one day
                return Math.round(differenceMs / oneDay);
            },

            saveNewCancelationReason() {
                if (this.cancelReason === '') {
                    return
                }

                this
                    .http()
                    .post(`/reservation/new-cancelation-reason`, {
                        type: 'CancelationReason',
                        value: this.cancelReason
                    }).then(response => {
                        this.isTakenReason = false;
                        this.reservation.cxl_reason = this.cancelReason;
                        this.cancelReason = this.$options.data.apply(this)['cancelReason'];
                        this.$modal.hide('mdl-cxl-reason');
                        this.loadReservationResources();
                    }).catch(e => {
                        console.log(e.message);
                        this.isTakenReason = true;
                    });
            },

            async loadReservationResources() {
                const { data } = await this.http().get('/reservation/resources');
                this.resource = data;

                console.log(this.reservation, 'reservation 88')

                if(this.reservation.state_id) {
                    this.loadStates();
                }
                if (this.reservation.hotel_id) {
                    this.loadUnits();
                }
                this.resource.ready = true;
            },

            async loadHotes() {
                const { data } = await this.http().get('/hotels');
                this.resource.hotels = data;
            },

            loadUnits() {
                const hotelSelected = this.resource.hotels.find( item => item.id === this.reservation.hotel_id);
                this.units = hotelSelected.units;
            },

            goBackWindows() {
                document.referrer ? window.location = document.referrer : history.back()
            },

            saveReservation() {
                console.log(this.reservation, '...FORM');
                this.loading = true;
                let isConfirmed = false;

                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        if(this.note.description){
                            this.reservation.notes.push(this.note)
                        }

                        const totalReservation = await this.checkTotalReservation()

                        if(totalReservation >= 5) {
                            Swal.fire({
                                title: 'Whoops! You have 5 or more reservations already checking in this day',
                                showCancelButton: true,
                                confirmButtonText: 'Save',
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.value) {
                                    this.saveReservationData();
                                }
                            })
                        } else {
                            await this.saveReservationData()
                        }
                    }
                });
            },

            async saveReservationData() {
                const payload = await this.parseToJsonString(this.reservation)
                this.http()
                    .post('/v1/reservations', payload)
                    .then( response => {
                        this.loading = false;
                        let {data} = response;
                        let {success,entry} = data;
                        if(success){
                            window.location.href = `${window.location.origin}/reservations/${entry.id}/edit`;
                        }
                    })
                    .catch( err => {});
            },

            async updateDataReservation() {
                const payload = await this.parseToJsonString(this.reservation)
                this.http()
                    .put(`/v1/reservations/${this.reservation.id}`, payload)
                    .then( ({ data }) => {
                        this.loading = false;
                        if (data.success) {
                            if (data.entry.cxl) {this.reservation.pres_date = null}
                            this.$toast.open({
                                type: 'success',
                                message: 'Reservation was updated',
                                position: 'top-right',
                            })
                        }
                    })
                    .catch( err => {});
            },

            async checkTotalReservation() {
                const { data } = await this.http().post('/v1/reservations/total/', {
                    checkin: this.customFormatter(this.reservation.checkin, 'YYYY-MM-DD'),
                });
                return data.total;
            },

            formatStringDate(value, item) {
                this.reservation[item] = this.customFormatter(value, 'YYYY-MM-DD hh:mm:ss');
            },

            updateReservation() {
                console.log(this.reservation, '...FORM TO UPDTE');
                this.loading = true;
                this.$validator.validateAll().then(async (result) => {
                    if (result) {
                        const totalReservation = await this.checkTotalReservation()

                        console.log(totalReservation, 'totalReservation...')

                        if(totalReservation >= 5 && this.reservation.cxl === null) {
                            Swal.fire({
                                title: 'Whoops! You have 5 or more reservations already checking in this day',
                                showCancelButton: true,
                                confirmButtonText: 'Save',
                            }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.value) {
                                    this.updateDataReservation();
                                }
                            })
                        } else {
                            await this.updateDataReservation()
                        }
                    }
                    this.loading = false;
                });
            },

            async parseDateFormate(object) {
                const { requested_at, completed_at, checkin, checkout, pres_date, cxl } = object;

                object.requested_at = requested_at ? this.formatDate(requested_at, 'YYYY-MM-DD') : null;
                object.completed_at = completed_at ? this.formatDate(completed_at, 'YYYY-MM-DD') : null;
                object.checkin = checkin ? this.formatDate(checkin, 'YYYY-MM-DD') : null;
                object.checkout = checkout ? this.formatDate(checkout, 'YYYY-MM-DD') : null;
                object.pres_date = pres_date ? this.formatDate(pres_date, 'YYYY-MM-DD') : null;
                object.cxl = cxl ? this.formatDate(cxl, 'YYYY-MM-DD') : null;

                return object;
            },

            async parseToJsonString(form) {
                let formCopy = Object.assign({},form);
                let {guests,kid_age,notes} = formCopy;
                formCopy.guests = JSON.stringify(guests);
                formCopy.kid_age = kid_age;
                formCopy.notes = JSON.stringify(notes);
                return await this.parseDateFormate(formCopy);
            },

            prepareGuestInfo() {
                let kidAge = this.reservation.kid_age;
                kidAge = kidAge === null ? "" : kidAge;
                let parseAge = kidAge === "" ? [] : Array.from(kidAge)
                if (parseAge.length > 0) {
                    this.reservation.children = parseAge.length
                    this.inputChildAges = Array.from(Array(parseAge.length).keys())
                } else {
                    this.reservation.kid_age = []
                    this.reservation.children = 0
                }
            },

            loadChildsAge() {
                //console.log(this.children, 'children')
                if (this.reservation.children === 0) {
                    this.inputChildAges = [];
                } else {
                    this.inputChildAges = Array.from(Array(this.children).keys())
                }
            },

            loadStates() {
                this.disabledStates = false;
                let filtered = this.resource.countries.find( item => item.id === this.reservation.country_id);
                if (filtered !== undefined) {
                    this.resource.states = filtered.states;
                }
            },

            prepareFields() {
                if (this.attachments.length > 0) {
                    for (var i = 0; i < this.attachments.length; i++) {
                        let attachment = this.attachments[i];
                        this.data.append('attachments[]', attachment);
                    }
                }
            },

            resetData() {
                this.data = new FormData(); // Reset it completely
                this.attachments = [];
                this.$refs['file-input'].reset();
            },

            uploadFieldChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                for (let i = files.length - 1; i >= 0; i--) {
                    this.attachments.push(files[i]);
                }
            },

            upload() {
                if (this.attachments.length === 0) {
                    this.$toast.error('You need to select 1 or more files...', {
                        position: 'top'
                    });
                }

                if(this.attachments.length > 0) {
                    this.loading = true;
                    this.prepareFields();
                    const config = {
                        headers: {'Content-Type': 'multipart/form-data'}
                    };
                    this.http()
                        .post(`/v1/documents?reservation_id=${this.reservation.id}`, this.data, config)
                        .then(({data}) => {
                            if (data.success) {
                                this.reservation.documents = [];
                                setTimeout(() => {
                                    this.loading = false;
                                    this.reservation.documents = data.entries;
                                    this.resetData();
                                }, 250)
                            }
                        })
                        .catch(err => {});
                }
            },

            preview(document){
                //window.open(`${window.location.origin}/${document.path}`);
                window.open(`${document.url}`);
            },

            removeDocument(document) {
                this.http().delete(`/reservation/documents/${document.id}`)
                    .then(({ data }) => {
                        if (data.success) {
                            this.documents = this.documents.filter(d => d.id !== document.id);
                            this.reLoadDocuments();
                        }
                    })
                    .catch(err => {});
            },

            prettyDate2() {
                const date = new Date(Number(new Date().getTime()));
                return date.toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });
            },

            clearDateTime(field) {
                console.log(this.reservation[field]);
                this.reservation[field] = null;
            },

            async fetchNotesFromDb() {
                return await this
                    .http()
                    .get(`/reservation/${this.reservation.id}/notes`, {
                        params: {
                            isOnlyNotes: this.onlyNotes
                        }
                    });
            },

            async reLoadNotes() {
                const { data } = await this.fetchNotesFromDb();
                this.reservation.notes = data;
            },

            saveNote() {
                if(this.note.description) {

                    let payload = {
                        reservation_id: this.reservation.id,
                        is_simple: this.onlyNotes,
                        description: this.note.description
                    };

                    this.http()
                        .post(`/v1/notes?reservation_id=${this.reservation.id}`, payload)
                        .then(response => {
                            let {data} = response;
                            let {success} = data;
                            if (success) {
                                this.reservation.notes = [];
                                setTimeout(() => {
                                    this.loading = false;
                                    this.reLoadNotes();
                                    this.note.description = null;
                                }, 250)
                            }
                        })
                        .catch(err => {});
                }
            },

            fillFlightInfo(w=1200, h=650) {

                let features = 'directories=no,menubar=no,location=no,resizable=no,scrollbars=no,status=yes,width=1200,height=650,top=150,left=250';
                window.open(`${window.location.origin}/mailable?reservation_id=${this.reservation.id}`, 'Preview_ET', features);

                let parseCheckin = this.formatDate(this.reservation.checkin, 'MMMM DD YYYY');
                let hotel = this.resource.hotels.find(h => h.id === this.reservation.hotel_id);
                let names = this.reservation.guests.map((g)=>{
                    return g.name
                }).join(", ");

                this.flight_info.description = `Buen Día:\n` +
                    `Solicito la siguiente transportación:\n\n` +
                    `${names}\n` +
                    `${parseCheckin}\n` +
                    `${this.reservation.adults} adultos\n` +
                    `Airlines #${this.reservation.flight_information}\n` +
                    `Llegada: ${this.reservation.flight_time}\n` +
                    `Destino: ${hotel.name}\n` +
                    `Saludos cordiales,`;
            },

            sendEmailTransportation() {
                this.http()
                    .post(`/email-transportation/${this.reservation.id}/send-now`, this.flight_info)
                    .then( response => {
                        $('#email-transportation').modal('hide');
                    })
            },

            async reLoadDocuments() {
                const { data } = await this.http().get(`/reservation/${this.reservation.id}/documents`);
                this.reservation.documents = data;
            },
        },
        watch: {
            'reservation.checkout': function (newValue,oldValue) {
                const checkin = this.momentInstance(this.reservation.checkin);
                const checkout = this.momentInstance(this.reservation.checkout);

                console.log("%c Watcher Checkout", "color: #fff; background-color: #3490dc; padding: 5px 10px; border-radius: 5px; font-size: 1.2em;", checkin, checkout);

                this.reservation.nights = this.getNumberOfNights(checkin, checkout);
            },
            'reservation.checkin': function (newValue) {
                //this.reservation.checkin = this.customFormatter(newValue);
                let nights = this.reservation.nights ? this.reservation.nights : 1;
                if (newValue !== null) {
                    this.reservation.checkout = this.addDays(newValue, nights, 'YYYY-MM-DD hh:mm:ss');
                }
            },
            'reservation.children': function (value) {
                let vm = this;
                vm.inputChildAges = [];

                console.log(value, 'value')

                if (value > 0) {
                    vm.inputChildAges = Array.from(Array(value).keys())
                    let kidsAge = this.reservation.kid_age.filter((item, index) => vm.inputChildAges.indexOf(index) !== -1)
                    if (kidsAge) {
                        vm.reservation.kid_age = kidsAge;
                    }
                } else {
                    vm.inputChildAges = []
                    vm.reservation.kid_age = []
                }
            }
        },

        computed: {
            showTabDocuments() {
                return this.isNew === true;
            },
            isOnlyNotes() {
                if (this.onlyNotes === false && this.onlyView === false) {
                    return true;
                }
                if (this.onlyNotes) {
                    return true;
                }
                return this.onlyView;
            },
            options () {
                return {
                    locale: 'en',
                    currency: 'USD'
                }
            },
            totalPaid: function() {
                const additional = this.reservation.additional === null ? 0 : this.reservation.additional;
                const rf = this.reservation.rf === null ? 0 : this.reservation.rf;
                if (this.reservation.additional === null && this.reservation.rf === null)
                    return 0;
                return (Number.parseFloat(additional) + Number.parseFloat(rf)).toFixed(2)
            }
        }
    }
</script>
<template>
    <div class="card mb-4">
        <div class="card-header py-3">
            <h3 class="mb-0 font-thin"> <i class="ni ni-calendar-grid-58 text-red mt-1"/> {{ isNew ? 'New' : 'Edit' }} Reservation</h3>
        </div>
        <div class="card-body py-2">
            <div class="nav-wrapper">
                <ul class="nav nav-pills-tab nav-fill flex-column flex-md-row" id="tabs-icons-text" role="tablist">
                    <li class="nav-custom pr-2 flex-none">
                        <a
                            class="nav-link mb-sm-3 mb-md-0 uppercase px-4 active"
                            id="tabs-icons-text-1-tab"
                            data-toggle="tab"
                            href="#tabs-icons-text-1" role="tab"
                            aria-controls="tabs-icons-text-1" aria-selected="true"
                        ><i class="fas fa-book text-green-800 mr-2"/> Reservation</a>
                    </li>
                    <li class="nav-custom pr-2">
                        <a
                            class="nav-link mb-sm-3 mb-md-0 uppercase px-4"
                            id="tabs-icons-text-2-tab"
                            data-toggle="tab" href="#tabs-icons-text-2"
                            role="tab" aria-controls="tabs-icons-text-2"
                            aria-selected="false"><i class="fas fa-users-cog"/> Guest Information</a>
                    </li>
                    <li class="nav-custom pr-2" v-if="!showTabDocuments && !onlyNotes">
                        <a class="nav-link mb-sm-3 mb-md-0 uppercase px-4"
                           id="tabs-icons-text-4-tab"
                           data-toggle="tab" href="#tabs-icons-text-4"
                           role="tab" aria-controls="tabs-icons-text-4"
                           aria-selected="false"><i class="fas fa-file-archive" /> Documents</a>
                    </li>

                    <li class="nav-custom pr-2" v-if="visibleTab && !onlyNotes">
                        <a
                            class="nav-link mb-sm-3 mb-md-0 uppercase px-4"
                            id="tabs-icons-text-5-tab"
                            data-toggle="tab" href="#tabs-icons-text-5"
                            role="tab" aria-controls="tabs-icons-text-5"
                            aria-selected="false"
                            @click.prevent="fetchMembershipItems()"
                        ><i class="fas fa-coins" /> Membership</a>
                    </li>

                    <li class="nav-custom">
                        <a
                            class="nav-link mb-sm-3 mb-md-0 uppercase px-4"
                            id="tabs-icons-text-3-tab"
                            data-toggle="tab" href="#tabs-icons-text-3"
                            role="tab" aria-controls="tabs-icons-text-3"
                            aria-selected="false"
                            @click="reLoadNotes"
                        ><i class="fas fa-file-medical-alt" /> Notes</a>
                    </li>

                </ul>
            </div>
            <div class="card shadow-none">
                <div class="card-body px-0">
                    <div class="tab-content" id="myTabContent">
                        <!-- Reservation TAB -->
                        <div class="tab-pane fade show active" id="tabs-icons-text-1" role="tabpanel" aria-labelledby="tabs-icons-text-1-tab">
                            <div class="form-row">
                                <div class="col-md-9 pr-3">
                                    <div class="form-row">
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="requested_at" class="text-sm">Date Requested:</label>
                                                <date-picker
                                                    v-model="reservation.requested_at"
                                                    v-validate="'required'"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    :input-attr="{ name: 'requested_at', id: 'requested_at' }"
                                                    placeholder="dd/mm/yyyy"
                                                    readonly="readonly"
                                                    :disabled="onlyView"
                                                    :value-type="valueType"
                                                />
                                                <span
                                                    v-show="errors.has('requested_at')"
                                                    class="help text-xs font-normal text-danger"
                                                >The Date Requested field is required.</span>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="date_completed" class="text-sm">Date Completed</label>
                                                <date-picker
                                                    v-model="reservation.completed_at"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    :input-attr="{ name: 'date_completed', id: 'date_completed' }"
                                                    placeholder="dd/mm/yyyy"
                                                    :disabled="onlyView"
                                                    :value-type="valueType"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3 pt-5 mt--3">
                                            <!--div class="mb-3 ml-3">
                                                <label class="form-control-label text-sm font-medium" for="no_show">No Show</label>
                                                <input type="checkbox" class="form-checkbox" id="no_show">
                                            </div-->
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="checkin" class="text-sm">Check In:</label>
                                                <date-picker
                                                    v-model="reservation.checkin"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    :input-attr="{name:'checkin', id:'checkin'}"
                                                    placeholder="dd/mm/yyyy"
                                                    calendar-button-icon="fas fa-calendar-alt"
                                                    :disabled="onlyView"
                                                    :formatter="momentFormat"
                                                    @change="formatStringDate($event, 'checkin')"
                                                    :value-type="valueType"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="checkout" class="text-sm">Check Out:</label>
                                                <date-picker
                                                    v-model="reservation.checkout"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    :input-attr="{name:'checkout', id:'checkout'}"
                                                    placeholder="dd/mm/yyyy"
                                                    :disabled="onlyView"
                                                    @change="formatStringDate($event, 'checkout')"
                                                    :value-type="valueType"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="pres_date" class="text-sm">Pres Date:</label>
                                                <date-picker
                                                    v-model="reservation.pres_date"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    :input-attr="{name:'pres_date', id:'pres_date'}"
                                                    placeholder="dd/mm/yyyy"
                                                    :disabled="onlyView"
                                                    :formatter="momentFormat"
                                                    :value-type="valueType"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3">
                                            <div class="form-group">
                                                <label for="nights" class="text-sm">Nights:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="nights"
                                                    :disabled="onlyView"
                                                    v-model="reservation.nights"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="additional" class="text-sm">Additional:</label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    :disabled="loading || onlyView"
                                                    id="additional"
                                                    v-model.number="reservation.additional"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="rf" class="text-sm">RF:</label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="rf"
                                                    :disabled="loading || onlyView"
                                                    v-model.number="reservation.rf"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="paid" class="text-sm">Paid:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="paid"
                                                    :disabled="true"
                                                    v-model="totalPaid"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3">
                                            <div class="form-group">
                                                <label for="paid_mode" class="text-sm">Paid Mode:</label>
                                                <select
                                                    class="form-control form-select"
                                                    id="paid_mode"
                                                    v-model="reservation.paid_mode_id"
                                                    :disabled="loading || !resource.ready || onlyView"
                                                >
                                                    <option value="" v-html="resource.ready ? '« Choose »': '...Loading...'" />
                                                    <option
                                                        v-for="mode in resource.paid_mode"
                                                        :value="mode.id"
                                                    >{{ mode.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="agency_id" class="text-sm">Agency:</label>
                                                <select
                                                    class="form-control form-select"
                                                    id="agency_id"
                                                    v-model="reservation.agency_id"
                                                    :disabled="loading || !resource.ready || onlyView"
                                                >
                                                    <option value="" v-html="resource.ready ? '« Choose »': '...Loading...'" />
                                                    <option
                                                        v-for="agency in resource.agencies"
                                                        :value="agency.id"
                                                    >{{ agency.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="hotel_id" class="text-sm">Hotel:</label>
                                                <select
                                                    class="form-control form-select focus:border-blue-200"
                                                    name="hotel_id"
                                                    id="hotel_id"
                                                    v-model="reservation.hotel_id"
                                                    @change="loadUnits"
                                                    :disabled="!resource.ready || onlyView"
                                                >
                                                    <option value="" v-html="resource.ready ? '« Choose »': '...Loading...'" />
                                                    <option
                                                        v-for="hotel in resource.hotels"
                                                        :value="hotel.id"
                                                    >{{ hotel.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="unit_id" class="text-sm">Unit:</label>
                                                <select
                                                    class="form-control form-select"
                                                    name="unit_id"
                                                    id="unit_id"
                                                    v-model="reservation.unit_id"
                                                    :disabled="loading || onlyView"
                                                >
                                                    <option value="">« Choose »</option>
                                                    <option
                                                        v-for="item in units"
                                                        :value="item.id"
                                                    >{{ item.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-xs-6 col-md-3">
                                            <div class="form-group">
                                                <label for="confirmation_number" class="text-sm">Confirmation Number:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="confirmation_number"
                                                    :disabled="onlyView"
                                                    v-model="reservation.confirmation_number"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-md-8">
                                            <div class="flex content-center pr-0 shadow border px-2 py-3 rounded w-auto">
                                                <div class="col-auto px-1">
                                                    <label for="flight_time" class="text-sm mb-0">Flight Time:</label>
                                                    <div class="input-group">
                                                        <input
                                                            type="time"
                                                            class="form-control"
                                                            id="flight_time"
                                                            v-model="reservation.flight_time"
                                                            :disabled="isNew || onlyView"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-auto px-1">
                                                    <label for="flight_information" class="text-sm mb-0">Flight Information:</label>
                                                    <div class="input-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            id="flight_information"
                                                            v-model="reservation.flight_information"
                                                            :disabled="isNew || onlyView"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-auto px-1">
                                                    <button
                                                        type="button"
                                                        class="btn mt-4 bg-gray-300 text-blue-600 border border-gray-700 hover:shadow-none"
                                                        data-toggle="modal"
                                                        data-target="#email-transportation1"
                                                        :disabled="!this.reservation.flight_time"
                                                        @click="fillFlightInfo"
                                                    ><i class="fas fa-envelope"/> Preview Email </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-group">
                                                <label for="certificate_number" class="text-sm">Certificate Number:</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="certificate_number"
                                                    v-model="reservation.certificate_number"
                                                    :disabled="onlyView"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-md-3 border-left border-gray-200 pl-3">
                                    <div class="form-group">
                                        <label for="cxl" class="text-sm">Cxl: <span class="italic text-sm text-gray-500">(Cancelation)</span></label>
                                        <date-picker
                                            v-model="reservation.cxl"
                                            :lang="lang"
                                            format="DD/MM/YYYY"
                                            width="100%"
                                            input-class="form-control"
                                            :input-attr="{ id: 'cxl', name: 'cxl' }"
                                            placeholder="dd/mm/yyyy"
                                            readonly="readonly"
                                            :disabled="onlyView"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <div class="flex justify-between items-center">
                                            <label for="cxl_reason" class="text-sm">Cancelation Reason:</label>
                                            <button
                                                class="px-2 py-1 bg-blue-600 rounded-sm mb-1 text-sm font-semibold text-white hover:bg-blue-700"
                                                @click="$modal.show('mdl-cxl-reason')"
                                                :disabled="!reservation.cxl"
                                            > Add + </button>
                                        </div>
                                        <select
                                            id="cxl_reason"
                                            class="form-control form-select"
                                            v-model="reservation.cxl_reason"
                                            :disabled="!reservation.cxl"
                                        >
                                            <option value="">« Choose »</option>
                                            <option
                                                v-for="reason in resource.cancelation_reasons"
                                                :value="reason.value"
                                                :key="reason.id"
                                            >{{ reason.value }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group mt-4">
                                        <div
                                            class="custom-control custom-radio mb-3"
                                        >
                                            <input
                                                id="6ab45a32ae59a"
                                                type="radio" class="custom-control-input"
                                                v-model="reservation.flag_color"
                                                value="red"
                                            >
                                            <label for="6ab45a32ae59a" class="custom-control-label">
                                                <i class="fas fa-flag fa-2x text-red-500"></i>
                                            </label>
                                        </div>
                                        <div
                                            class="custom-control custom-radio mb-3"
                                        >
                                            <input
                                                id="6ab45a32ae59c"
                                                type="radio"
                                                class="custom-control-input"
                                                v-model="reservation.flag_color"
                                                value="yellow"
                                            />
                                            <label
                                                for="6ab45a32ae59c"
                                                class="custom-control-label"
                                            >
                                                <i class="fas fa-flag fa-2x text-yellow-500"></i>
                                            </label>
                                        </div>
                                        <div
                                            class="custom-control custom-radio mb-3"
                                        >
                                            <input
                                                id="6ab45a32ae59e"
                                                type="radio"
                                                class="custom-control-input"
                                                v-model="reservation.flag_color"
                                                value="green"
                                            />
                                            <label
                                                for="6ab45a32ae59e"
                                                class="custom-control-label"
                                            >
                                                <i class="fas fa-flag fa-2x text-green-500"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group mt-4">
                                        <div class="flex items-center space-x-3 my-2 mb-3 ">
                                            <label for="flag_arrival" class="text-sm font-semibold">Pre-Arrival:</label>
                                            <button
                                                @click="reservation.flag_arrival = null"
                                                class="btn btn-sm btn-outline-danger"
                                            >Clear Selection</button>
                                        </div>
                                        <div
                                            class="custom-control custom-radio mb-3"
                                            v-for="preArrival in valuesFlagArrival"
                                        >
                                            <input
                                                :id="preArrival.value + 'pre_Arrival_'"
                                                type="radio" class="custom-control-input"
                                                v-model="reservation.flag_arrival"
                                                :value="preArrival.value"
                                            >
                                            <label
                                                :for="preArrival.value + 'pre_Arrival_'"
                                                class="custom-control-label flex space-x-3 items-center"
                                            >
                                                <i
                                                    class="fas fa-plane-arrival fa-2x"
                                                    :class="[
                                                        preArrival.value == 'red' ? 'text-red-600' : '',
                                                        preArrival.value == 'orange' ? 'text-yellow-500' : '',
                                                        preArrival.value == 'green' ? 'text-green-500' : '',
                                                        preArrival.value == 'blue' ? 'text-blue-500' : ''
                                                    ]"
                                                />
                                                <span>{{ preArrival.text }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <!-- GUESTS TAB -->
                        <div class="tab-pane fade" id="tabs-icons-text-2" role="tabpanel" aria-labelledby="tabs-icons-text-2-tab">
                            <div
                                class="form-row border-b border-blue-400 border-dotted mb-2"
                                v-for="(guest, index) in reservation.guests"
                            >
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label
                                            :for="`name_` + index"
                                            class="text-sm"
                                            v-html="guest.is_principal===true ? 'Name (Principal):' : 'Name (Spouse):'"
                                        />
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`name_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.name"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label :for="`age_` + index" class="text-sm">Age:</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`age_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.age"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label :for="`ethnic_group_` + index" class="text-sm">Ethnic Group:</label>
                                        <select
                                            class="form-control form-select"
                                            :id="`ethnic_group_` + index"
                                            v-model="guest.ethnic_group"
                                            :disabled="onlyView"
                                        >
                                            <option value="">« Choose »</option>
                                            <option value="NORTH AMERICAN CAUCASIAN">NORTH AMERICAN CAUCASIAN</option>
                                            <option value="AFRO-AMERICAN">AFRO-AMERICAN</option>
                                            <option value="LATINOS">LATINOS</option>
                                            <option value="ASIAN (CHINESE,JAPANESE,THAI)">ASIAN (CHINESE,JAPANESE,THAI)</option>
                                            <option value="EASTERN EUROPE">EASTERN EUROPE</option>
                                            <option value="HINDUS">HINDUS</option>
                                            <option value="FRENCH CANADIANS">FRENCH CANADIANS</option>
                                            <option value="MIXED">MIXED</option>
                                            <option value="OTHER">OTHER</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label :for="`phone_` + index" class="text-sm">Phone:</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`phone_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.phone"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label :for="`alternative_phone_` + index" class="text-sm">Alternative Phone:</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`alternative_phone_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.alternative_phone"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label :for="`email_` + index" class="text-sm">Email:</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`email_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.email"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label :for="`alternative_email_` + index" class="text-sm">Alternative Email:</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            :id="`alternative_email_` + index"
                                            :disabled="onlyView"
                                            v-model="guest.alternative_email"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="adults" class="text-sm">Adults:</label>
                                        <input type="text"
                                               class="form-control" id="adults"
                                               name="adults"
                                               v-model="reservation.adults"
                                               v-validate="'required'"
                                               :disabled="onlyView"
                                        />
                                        <span
                                            v-show="errors.has('adults')"
                                            class="help text-xs font-normal text-danger"
                                        >
                                            The Date Requested field is required.
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="childs" class="text-sm">Children:</label>
                                        <select
                                            class="form-control form-select"
                                            id="childs"
                                            v-model="reservation.children"
                                            :disabled="onlyView"
                                        >
                                            <option value="0">« Choose »</option>
                                            <option
                                                v-for="children in childs"
                                                :value="++children"
                                                :id="children"
                                            >{{ children }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-for="(age,index) in inputChildAges" :key="`age-child-${index}`" class="flex col-md-2 pl-2 mr-0">
                                    <div class="form-group w-full">
                                        <label
                                            for="age_child" class="text-sm"
                                        >Child Age :</label>
                                        <input
                                            type="number" min="1" max="17"
                                            class="form-control"
                                            id="age_child"
                                            v-model="reservation.kid_age[index]"
                                            v-validate="{required :true}"
                                            :name="`age-child-${index}`"
                                        />
                                        <span
                                            v-show="errors.has(`age-child-${index}`)"
                                            class="help text-xs font-normal text-danger"
                                        >The Child Age {{ age }} field is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="country" class="text-sm">Country:</label>
                                        <select
                                            class="form-control form-select"
                                            id="country"
                                            v-model="reservation.country_id"
                                            @change="loadStates"
                                            :disabled="onlyView"
                                        >
                                            <option value="">« Choose »</option>
                                            <option
                                                v-for="country in resource.countries"
                                                :value="country.id"
                                                :id="country.id"
                                            >{{ country.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="state" class="text-sm">State:</label>
                                        <select
                                            class="form-control form-select"
                                            id="state"
                                            v-model="reservation.state_id"
                                            :disabled="disabledStates || onlyView"
                                        >
                                            <option value="">« Choose »</option>
                                            <option
                                                class="uppercase"
                                                v-for="item in resource.states"
                                                :value="item.id"
                                            >{{ item.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-2 pt-5 mt--3">
                                    <div class="form-group">
                                        <label for="cohab" class="text-sm">Cohab:</label>
                                        <input
                                            class="form-checkbox"
                                            type="checkbox"
                                            id="cohab"
                                            v-model="reservation.cohab"
                                            :disabled="onlyView"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- NOTES TAB -->
                        <div class="tab-pane fade" id="tabs-icons-text-3" role="tabpanel" aria-labelledby="tabs-icons-text-3-tab">
                            <div
                                class="row"
                                v-if="isOnlyNotes"
                            >
                                <div class="col-xs-6 col-md-10 pr-0">
                                    <div class="form-group mb-0">
                                        <label for="notes" class="text-sm">Notes:</label>
                                        <textarea v-model="note.description" class="form-control" rows="2" name="notes" id="notes" />
                                        <button
                                            class="btn btn-sm hover:text-white border-green-400 hover:bg-green-500 hover:border-green-600 mt-2"
                                            @click="saveNote"
                                            v-if="!isNew"
                                            :disabled="!isOnlyNotes"
                                        >
                                            <i class="fa fa-save" /> Save Note
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="!isNew">
                                <div class="col-xl-12">
                                    <div class="w-full">
                                        <div class="card-header border-0 py-1">
                                            <div class="row">
                                                <h3 class="mb-0 font-thin"><i class="fa fa-file text-green-600" /> Notes</h3>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table align-items-center table-flush table-fixed">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th scope="col" class="uppercase w-1/12 px-2">#</th>
                                                        <th scope="col" class="uppercase w-8/12 px-2">Description</th>
                                                        <th scope="col" class="uppercase w-3/12 px-2">Created At</th>
                                                        <th v-if="onlyNotes" scope="col" class="uppercase w-3/12 px-2">Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="list">
                                                    <tr v-for="(note, index) in reservation.notes" :key="index">
                                                        <td class="px-2 text-left">{{ index + 1 }}</td>
                                                        <td class="px-2" style="white-space: normal !important;" >
                                                            <div v-html="note.description"></div>
                                                            <div
                                                                v-show="note.hasItems"
                                                                class="pl-4"
                                                            >
                                                                <span
                                                                    class="block text-green-600 font-sans"
                                                                    v-for="(el, key) in note.items"
                                                                    :key="key"
                                                                    v-html="el.title"
                                                                ></span>
                                                            </div>
                                                        </td>
                                                        <td class="px-2">{{ note.createdAt }}</td>
                                                        <td v-if="onlyNotes" class="px-2">{{ note.createdBy }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- DOCUMENTS TAB -->
                        <div class="tab-pane fade" id="tabs-icons-text-4" role="tabpanel" aria-labelledby="tabs-icons-text-4-tab" v-if="!showTabDocuments">
                            <div class="form-row align-items-center" v-if="!onlyView">
                                <div class="col-auto">
                                    <input-file
                                        ref="file-input"
                                        name="documents"
                                        @change="uploadFieldChange"
                                        accept="application/pdf"
                                    />
                                    <button
                                        type="button"
                                        class="btn btn-light rounded-none w-full block mb-2"
                                        @click="upload"
                                    ><i class="fas" :class="[loading ? 'fa-spinner fa-spin' : 'fa-upload']" /> Upload</button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="w-full">
                                        <div class="card-header border-0 py-0">
                                            <div class="row py-2">
                                                <h3 class="mb-0 font-thin text-gray-600"><i class="far fa-file-pdf text-green-600"/> Documents</h3>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <base-loader v-if="loading" class="table-loader" />
                                            <table class="table align-items-center table-flush">
                                                <thead class="thead-light">
                                                    <tr>
                                                        <th scope="col" class="uppercase">#</th>
                                                        <th scope="col" class="uppercase">Name</th>
                                                        <th scope="col" class="uppercase">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="list">
                                                    <tr v-for="document in reservation.documents">
                                                        <td>{{ document.id }}</td>
                                                        <td>{{ document.name }}</td>
                                                        <td>
                                                            <button class="btn btn-sm btn-outline-info text-blue-400 border-0" @click="preview(document)">
                                                                <i class="fas fa-eye"/>
                                                            </button>
                                                            <button class="btn btn-sm btn-outline-danger border-0" @click="removeDocument(document)">
                                                                <i class="far fa-trash-alt"/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- FINANCIAL TAB -->
                        <div
                            class="tab-pane fade"
                            id="tabs-icons-text-5"
                            role="tabpanel"
                            aria-labelledby="tabs-icons-text-5-tab"
                        >
                            <Memberships />
                        </div>
                    </div>
                </div>

                <hr class="my-3">

                <div class="form-group">
                    <button
                        type="button"
                        class="btn border-gray-600 hover:bg-gray-600 hover:text-white py-1"
                        @click="goBackWindows"
                    >
                        <i class="fas fa-chevron-left"/> Back
                    </button>
                    <button
                        class="btn text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white py-1"
                        type="submit"
                        @click="saveReservation"
                        v-if="isNew"
                    >
                        <i class="fas" :class="[loading ? 'fa-spinner fa-spin' : 'fa-save']" /> Save
                    </button>
                    <button
                        class="btn text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white py-1"
                        type="submit"
                        @click="updateReservation"
                        :disabled="!isOnlyNotes"
                        v-if="!isNew"
                    >
                        <i class="fas" :class="[loading ? 'fa-spinner fa-spin' : 'fa-edit']" /> Update
                    </button>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div class="modal fade" id="email-transportation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom border-gray-200">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Flight Information
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pb-0">
                        <textarea
                            class="form-control"
                            id="description"
                            rows="10"
                            :disabled="true"
                            v-model="flight_info.description"
                        />
                    </div>
                    <div class="modal-footer py-3">
                        <button
                            type="button"
                            class="btn btn-sm btn-secondary"
                            data-dismiss="modal"
                        >Close</button>
                        <button
                            type="button"
                            class="btn btn-sm btn-primary"
                            @click="sendEmailTransportation"
                        >Send email</button>
                    </div>
                </div>
            </div>
        </div>

        <modal
            name="mdl-cxl-reason"
            height="260px"
        >
            <div class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                <div class="relative block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden">
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button
                            type="button"
                            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            @click="$modal.hide('mdl-cxl-reason')"
                        >
                            <!-- Heroicon name: outline/x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 w-full text-left sm:mt-0 sm:text-left">
                            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">New Reason</h3>
                            <div class="mt-2">
                                <input
                                    type="text"
                                    class="form-input block w-full sm:text-sm sm:leading-5" placeholder="Reason"
                                    v-model="cancelReason" @change="cancelReason = $event.target.value.toUpperCase()"
                                />
                                <span v-if="isTakenReason" class="text-red-500 block w-full text-sm font-semibold">The value has already been taken</span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex justify-end">
                        <button
                            type="button"
                            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            @click="saveNewCancelationReason"
                        >Save</button>
                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>
<style lang="scss" scoped>
    .calendar-picker {
        span.day {
            font-size: .735rem !important;
        }
    }
</style>

import * as types from './mutation_types'
import * as membershipType from '../memberships/mutation_types'

export const receiveReservation = async ({ dispatch, commit, rootStore }, data) => {
    return new Promise((resolve) => {
        commit(types.RECEIVE_RESERVATION, data);
        commit(`memberships/${membershipType.UPDATE_ENDPOINT}`, `/reservation/${data.id}/memberships`, {root:true})
        resolve(data);
    });
};

export const fetchReservation = ({ commit }) => {};

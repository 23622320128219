import * as types from './mutation_types';
import axios from '@/utils/axios_utils'

export const receiveItemsSuccess = ({ commit }, data) => commit(types.RECEIVE_MEMBERSHIPS_SUCCESS, data);
export const updateEndpoint = ({ commit }, data) => commit(types.UPDATE_ENDPOINT, data);

export const fetchMemberships = ({ state, dispatch, commit }) => {
    commit(types.REQUEST_MEMBERSHIPS);
    axios.get(state.endpoint)
        .then(({ data }) => dispatch('receiveItemsSuccess', data))
        .catch((err) => console.log( err));
};

export const createOrUpdateMembership = ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
        axios.post(data.endpoint, data.form)
            .then( ({data}) => {
                dispatch('fetchMemberships');
                resolve(data)
            })
            .catch((err) => reject(err))
    });
};

export const deleteMembership = ({ state, dispatch }, item) => {
    return new Promise((resolve, reject) => {
        axios.delete(`/reservation/${item.id}/memberships`)
            .then(({ data }) => {
                dispatch('fetchMemberships');
                resolve(data)
            })
            .catch((err) => reject(err));
    });
};

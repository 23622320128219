<template>
    <div class="row">
        <div class="col-md-12">
            <div class="flex">
                <span class="p-1 mr-2">New Membership</span>
                <button
                    type="button"
                    class="px-2 h-8 rounded-sm btn-slack"
                    data-toggle="modal" data-target="#modalMembership"
                    @click="resetModal"
                ><i class="fas fa-plus-square"/></button>
            </div>

            <div class="table-responsive">
                <base-loader v-if="isLoading" class="table-loader" />
                <table class="table mt-3">
                    <thead class="thead-light">
                        <tr>
                            <th class="px-1">Contract Number</th>
                            <th>Type</th>
                            <th>Sale price</th>
                            <th class="px-1">Closing Cost</th>
                            <th class="px-1">Total</th>
                            <th>Date</th>
                            <th class="px-1">Status</th>
                            <th>Canceled At</th>
                            <th class="w-10"><!--Actions--></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="entry in entries" :class="{'bg-red-200': entry.isCancel }">
                            <td class="w-12 px-1">{{ entry.contract_number }}</td>
                            <td class="uppercase">{{ entry.typeName }}</td>
                            <td>{{ entry.sales_price }}</td>
                            <td class="px-1">{{ entry.closing_cost }}</td>
                            <td class="px-1">{{ entry.total }}</td>
                            <td>{{ entry.date }}</td>
                            <td class="uppercase px-1">{{ entry.statusName }}</td>
                            <td class="font-italic">{{ entry.canceledAt }}</td>
                            <td class="py-1">
                                <div class="flex">
                                    <button
                                        class="btn btn-sm btn-outline-info text-blue-400 hover:bg-blue-400 border-0"
                                        @click="editMembership(entry)"
                                        data-toggle="modal"
                                        data-target="#modalMembership"
                                    ><i class="far fa-edit" /></button>
                                    <button
                                        class="btn btn-sm btn-outline-danger border-0"
                                        @click="removeMembership(entry)"
                                    ><i class="far fa-trash-alt"/></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal fade" id="modalMembership" tabindex="-1" role="dialog" aria-labelledby="modalMembershipLabel">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header border-bottom border-gray-200">
                        <h5 class="modal-title" id="modalMembershipLabel">
                            New Membership
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="storeMembership('form-m')" data-vv-scope="form-m">
                        <div class="modal-body pb-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="contract_number" class="text-sm">Contract Number:</label>
                                                <input
                                                    name="contract_number"
                                                    id="contract_number"
                                                    v-validate="'required'"
                                                    v-model="reservation.financial.contract_number"
                                                    class="form-control"
                                                />
                                                <span v-show="errors.has('form-m.contract_number')" class="help text-xs font-normal text-danger">The field is required.</span>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_date" class="text-sm">Date:</label>
                                                <date-picker
                                                    id="financial_date"
                                                    name="financial_date"
                                                    v-model="reservation.financial.date"
                                                    :lang="lang"
                                                    format="DD/MM/YYYY"
                                                    width="100%"
                                                    input-class="form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    readonly="readonly"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="sales_price" class="text-sm">Sales Price:</label>
                                                <input
                                                    id="sales_price"
                                                    v-model="reservation.financial.sales_price"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="closing_cost" class="text-sm">Closing Cost:</label>
                                                <input
                                                    id="closing_cost"
                                                    v-model="reservation.financial.closing_cost"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_total" class="text-sm">Total:</label>
                                                <input
                                                    id="financial_total"
                                                    v-model="reservation.financial.total"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-md-1">
                                            <div class="form-group">
                                                <label for="financial_percent" class="text-sm font-semibold text-center">%</label>
                                                <input
                                                    id="financial_percent"
                                                    v-model="reservation.financial.percent"
                                                    class="form-control text-center"
                                                    placeholder="35%"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_down_payment" class="text-sm">Down Payment:</label>
                                                <input
                                                    id="financial_down_payment"
                                                    v-model="reservation.financial.down_payment"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_dp_percent" class="text-sm">DP + CC:</label>
                                                <input
                                                    id="financial_dp_percent"
                                                    v-model="reservation.financial.dp"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_balance" class="text-sm">Balance:</label>
                                                <input
                                                    id="financial_balance"
                                                    v-model="reservation.financial.balance"
                                                    class="form-control"
                                                    v-currency="currencyFormat"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_type" class="text-sm">Type:</label>
                                                <select
                                                    class="form-control form-select uppercase"
                                                    id="financial_type"
                                                    name="financial_type"
                                                    v-validate="'required'"
                                                    v-model="reservation.financial.type"
                                                >
                                                    <option value="">« Choose »</option>
                                                    <option value="MEMBERSHIP">Membership</option>
                                                    <option value="MEXIT">Mexit</option>
                                                    <option value="RACK">Rack</option>
                                                </select>
                                                <span v-show="errors.has('form-m.financial_type')" class="help text-xs font-normal text-danger">The field is required.</span>
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="financial_status" class="text-sm">Status:</label>
                                                <select
                                                    class="form-control form-select uppercase"
                                                    id="financial_status"
                                                    name="financial_status"
                                                    v-validate="'required'"
                                                    v-model="reservation.financial.status"
                                                >
                                                    <option value="">« Choose »</option>
                                                    <option value="PRO">Active</option>
                                                    <option value="PENDER">Pender</option>
                                                    <option value="CXL">Canceled</option>
                                                    <option value="ONHOLD">On Hold</option>
                                                </select>
                                                <span v-show="errors.has('form-m.financial_status')" class="help text-xs font-normal text-danger">The field is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer py-3 border-top">
                            <button
                                type="button"
                                class="btn btn-sm px-3 btn-secondary uppercase text-gray-700"
                                data-dismiss="modal"
                                @click="errors.clear('form-m')"
                            >Close</button>

                            <button
                                type="submit"
                                class="btn btn-sm px-3 btn-slack uppercase font-sans"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Reservation from "@/mixins/reservation";
    import Customs from "@/mixins/customs";
    import DatePicker from 'vue2-datepicker'
    import { mapActions, mapGetters, mapState } from 'vuex'
    import { parse } from "vue-currency-input";

    export default {
        mixins: [Reservation, Customs],
        components: {DatePicker},

        data() {
            return {
                isNewRecord: false
            }
        },

        mounted() {
            //this.fetchMembershipItems();
        },

        computed: {
            ...mapState({
                entries: state => state.memberships.items
            }),
            ...mapGetters({
                selectedReservation: 'reservations/getSelectedReservation',
                isLoading: 'memberships/isLoading'
            }),
            options() {
                return this.currencyFormat
            },
        },

        methods: {
            ...mapActions({
                fetchMembershipItems: 'memberships/fetchMemberships',
                deleteMembership: 'memberships/deleteMembership',
                createMembership: 'memberships/createOrUpdateMembership'
            }),

            resetModal() {
                Object.assign(this.reservation.financial, this.$options.data.apply(this)['reservation.financial'])
            },

            storeMembership(scope) {
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        let reservationID = this.selectedReservation.id;

                        let endpoint = this.reservation.financial.id
                            ? `/r/membership/${this.reservation.financial.id}`
                            : `/reservation/${reservationID}/membership`;

                        let savedMemberShip = this.createMembership({ endpoint: endpoint, form: this.reservation.financial});

                        console.log(savedMemberShip, 'savedMemberShip')

                        $('#modalMembership').modal('hide');
                    }
                });
            },

            editMembership(item) {
                const membershipItem = Object.assign({}, item);
                this.reservation.financial = membershipItem;
                this.updateDPCC(membershipItem)
            },

            removeMembership(item) {
                this.deleteMembership(item)
                    .then( data => {
                        this.$toast.open({type: 'success', message: data.message, position: 'top-right'});
                    });
            },

            updateDPCC(item) {
                const sp = parse(item.sales_price, this.options);
                const closingCost = parse(item.closing_cost, this.options);
                this.reservation.financial.down_payment = (sp * item.percent/ 100).toFixed(2);
                const downPay = parse(item.down_payment, this.options);
                this.reservation.financial.dp = (closingCost + downPay).toFixed(2);
            }
        },

        watch: {
            'reservation.financial.closing_cost': function(newVal) {
                if (newVal !== 'NaN' && typeof newVal === "number") {
                    this.reservation.financial.closing_cost = Number.parseFloat(newVal).toFixed(2)
                }
            },
            'reservation.financial.sales_price': function(value) {
                let newValue = Number.parseFloat(value.replace(/[^\d\.]/g, ""));
                const percent = newValue * 12/ 100;

                console.log(newValue, percent);

                this.reservation.financial.closing_cost = percent;
                this.reservation.financial.total = (percent + newValue).toFixed(2);
            },
            'reservation.financial.percent': function(value) {
                const sp = parse(this.reservation.financial.sales_price, this.options);
                const closingCost = parse(this.reservation.financial.closing_cost, this.options);
                this.reservation.financial.down_payment = (sp * value/ 100).toFixed(2);
                const downPay = parse(this.reservation.financial.down_payment, this.options);
                this.reservation.financial.dp = (closingCost + downPay).toFixed(2);
            },
            'reservation.financial.down_payment': function(value) {
                const sp = parse(this.reservation.financial.sales_price, this.options);
                const dp = parse(this.reservation.financial.down_payment, this.options);
                this.reservation.financial.balance = (sp - dp).toFixed(2);
            },
        }
    }
</script>

<template>
    <div class="col-md-12 px-0">
        <div class="form-row">
            <div class="form-group mr-3">
                <label for="from_date" class="text-sm">From Date:</label>
                <date-picker
                    id="from_date"
                    name="from_date"
                    v-model="form.from_date"
                    v-validate="'required'"
                    :lang="lang"
                    format="DD/MM/YYYY"
                    width="100%"
                    input-class="form-control"
                    placeholder="dd/mm/yyyy"
                    readonly="readonly"
                ></date-picker>
            </div>

            <div class="form-group mr-3">
                <label for="to_date" class="text-sm">To Date:</label>
                <date-picker
                    id="to_date"
                    name="to_date"
                    v-model="form.to_date"
                    v-validate="'required'"
                    :lang="lang"
                    format="DD/MM/YYYY"
                    width="100%"
                    input-class="form-control"
                    placeholder="dd/mm/yyyy"
                    readonly="readonly"
                ></date-picker>
            </div>

            <div class="form-group mr-3">
                <label for="rate" class="text-sm">Rate:</label>
                <input
                    type="text"
                    name="rate"
                    id="rate"
                    class="form-control"
                    v-model="form.rate"
                />
            </div>

            <div class="form-group">
                <div class="flex flex-col h-full justify-between">
                    <div></div>
                    <button
                        type="button"
                        class="btn text-white bg-red-500 hover:bg-red-600"
                        @click="submitRateForm"
                    ><i class="far fa-save"></i> Save</button>
                </div>
            </div>
        </div>

        <div class="form-row" :class="[form.errors.length ? 'mt-1' : 'mt-4']">
            <!-- Form Errors -->
            <div class="alert alert-danger bg-red-200 w-full px-4 py-2 rounded-none border-red-500 border-l-4 border-t-0 border-r-0 border-b-0"
                 v-if="form.errors.length > 0"
            >
                <p class="mb-0 text-red-600 block"><strong>Whoops!</strong> Something went wrong!</p>
                <ul class="text-red-600 font-semibold">
                    <li v-for="error in form.errors">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <loading :active.sync="loading"></loading>

            <div class="title text-gray-600 font-medium uppercase">
                <i class="far fa-arrow-alt-circle-down"></i> Rates between Dates
            </div>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Rate</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="rate in rates">
                        <td class="text-gray-600">{{ rate.from_date }}</td>
                        <td class="text-gray-600">{{ rate.to_date }}</td>
                        <td class="text-gray-600">{{ rate.rate }}</td>
                        <td class="">
                            <button
                                class="btn btn-sm hover:shadow-none hover:text-red-500"
                                @click="removeItem(rate)"
                            ><i class="fas fa-trash-alt"></i></button>
                        </td>
                    </tr>
                    <tr v-if="rates.length === 0">
                        <td colspan="4" class="text-center">
                            <span
                                class="italic text-gray-600 p-2 text-base"
                            >No result found <i class="far fa-comment-dots"></i></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import Customs from "../mixins/customs";
    import DatePicker from 'vue2-datepicker';
    import Loading from 'vue-loading-overlay';

    export default {
        mixins: [Customs],

        props: {
            hotel: {
                type : Number,
                required : true
            }
        },

        components: {
            Loading,
            DatePicker
        },

        data() {
            return {
                ready: true,
                loading: false,
                rates: [],
                form: {
                    from_date: null,
                    to_date: null,
                    rate: null,
                    errors: []
                }
            }
        },

        mounted() {
            this.loadHotelRates();
        },

        methods: {
            async loadHotelRates() {
                this.loading = true;
                const {data} = await this.http().get(`/hotel/${this.hotel}/rates`);
                this.rates = data;
                this.loading = false;
            },

            submitRateForm() {
                this.loading = true;
                this.http()
                    .post(`/hotel/${this.hotel}/rates`, this.form)
                    .then( ({data}) => {
                        if (data.success) {
                            this.loadHotelRates();
                            this.form.errors = [];
                            this.form.from_date = null; this.form.to_date = null; this.form.rate = null;
                            this.loading = false;
                        }
                    })
                    .catch( error => {
                        if (typeof error.response.data === 'object') {
                            this.form.errors = _.flatten(_.toArray(error.response.data.errors));
                        } else {
                            this.form.errors = ['Something went wrong. Please try again.'];
                        }
                        this.loading = false;
                    });
            },

            removeItem(item) {
                this.loading = true;
                this.http()
                    .delete(`rates/${item.id}`)
                    .then( ({ data }) => {
                        if(data.success) {
                            this.loadHotelRates();
                            this.loading = false;
                        }
                    })
                    .catch( error => {});
            }
        },

        watch: {
            'form.from_date': function (value) {
                this.form.from_date = this.customFormatter(value);
            },
            'form.to_date': function (value) {
                this.form.to_date = this.customFormatter(value);
            }
        }

    }
</script>

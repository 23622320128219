<template>
    <div class="base-input">
        <label>
            <input
                ref="baseInput"
                v-model="inputValue"
                :type="type"
                :disabled="disabled"
                :readonly="readOnly"
                :name="name"
                @input="handleInput"
                @change="handleChange"
                @keyup="handleKeyupEnter"
                @keydown="handleKeyDownEnter"
            />
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            value: {
                type: [String, Number, File],
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                inputValue: this.value,
            }
        },

        methods: {
            handleInput (e) {
                this.$emit('input', this.inputValue)
            },
            handleChange (e) {
                this.$emit('change', this.inputValue)
            },
            handleKeyupEnter (e) {
                this.$emit('keyup', this.inputValue)
            },
            handleKeyDownEnter (e) {
                this.$emit('keydown', e, this.inputValue)
            },
        }
    }
</script>

<style scoped>

</style>

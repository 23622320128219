<template>
    <div class="flex w-full items-center justify-center">
        <label class="w-48 flex flex-col items-center px-1 py-4 bg-white text-blue-600 hover:text-blue-300 rounded-lg shadow-lg tracking-wide uppercase border-2 border-dotted cursor-pointer mb-1">
            <svg
                class="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
            ><path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span class="mt-2 text-xs leading-normal">Select a file</span>
            <input
                ref="inputFile"
                type="file"
                :name="name"
                class="hidden"
                :multiple="multiple"
                :accept="accept"
                @change="handleChange"
            />
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                default: ''
            },
            value: {
                type: [String, Number, File],
                default: ''
            },
            multiple: {
                type: Boolean,
                default: false
            },
            accept: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                selectedFile: null,
                inputValue: this.value,
            }
        },

        methods: {
            handleChange (evt) {
                this.$emit('change', evt);
                // Normal handling
                this.setFiles(evt.target.files || evt.dataTransfer.files)
            },

            setFiles(files = []) {
                if (!files) {
                    this.selectedFile = null
                } else if (this.multiple) {
                    // Convert files to array
                    const filesArray = [];
                    for (let i = 0; i < files.length; i++) {
                        filesArray.push(files[i])
                    }
                    // Return file(s) as array
                    this.selectedFile = filesArray
                } else {
                    // Return single file object
                    this.selectedFile = files[0] || null
                }
            },

            reset() {
                try {
                    // Wrapped in try in case IE 11 craps out
                    this.$refs.input.value = ''
                } catch (e) {}
                this.$refs.inputFile.type = '';
                this.$refs.inputFile.type = 'file';
                this.selectedFile = this.multiple ? [] : null
            },

            onReset() {
                this.selectedFile = this.multiple ? [] : null
            }

        }
    }
</script>

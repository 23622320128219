<script>
import ReservationComponent from "../components/ReservationComponent";
import Customs from "../mixins/customs";
import { mapActions } from "vuex";

export default {
    components : {ReservationComponent},
    mixins: [Customs],
    props: {
        onlyNotes: {
            type: [Boolean, String],
            default: false
        },
        reservation : {
            type : Object,
            required : true
        }
    },

    data() {
        return {
            reservationItem : {}
        }
    },
    computed: {
        isReadOnly() {
            return this.onlyNotes === '1';
        }
    },
}
</script>
<template>
    <reservation-component
        :is-new="false"
        :item="reservation"
        :only-view="isReadOnly"
        :only-notes="isReadOnly"
    />
</template>

import * as actions from './actions';
import * as getters from './getters'
import mutations from './mutations';

// initial state
const initialState = {
    items: [],
    selectedReservation: null,
};

export default {
    namespaced: true,
    state : initialState,
    actions,
    getters,
    mutations,
};

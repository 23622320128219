import * as actions from './actions';
import * as getters from './getters';
import mutations from './mutations';

const initialState = {
    endpoint: '/reservation',
    isLoading: false,
    parentReservation: null,
    items: []
};

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state: initialState,
};
